import {PD_MD, PD_SM, PD_XSM, SZ_MD, SZ_SM} from "./dimens";
import {Box, Button, Card, SvgIcon, Typography} from "@mui/material";
import React, {ReactElement} from "react";
import defaultImage from "../res/images/default_music.png";
import {EmptyConfig, Item} from "./types";
import {mediumGray} from "./colors";

export type StyledListItemProps<T> = {
  object?: T,
  variant?: "text" | "default",
  img?: string,
  icon?: typeof SvgIcon,
  title: string,
  text: string,
  accessory?: ReactElement,
  onClick?: (object: T) => void,
}

export function StyledListItem<T>(props: StyledListItemProps<T>) {
  const variant = props.variant || "default";
  return <Button
    color="secondary"
    variant="text"
    onClick={() => props.onClick?.(props.object as T)}>
    <Box style={{
      width: "100%",
      height: 80,
      padding: 8,
      display: "flex",
      gap: PD_SM,
      textTransform: "none",
      alignItems: "center",
    }}>
      {variant === "default"
        ? props.icon ?
          <Box style={{width: SZ_MD, height: SZ_MD, display: "flex", alignItems: "center", justifyContent: "center"}}>
            <props.icon style={{width: SZ_SM, height: SZ_SM}}/>
          </Box>
          : <Card style={{width: SZ_MD, height: SZ_MD, flexShrink: 0}}>
            <img src={props.img || defaultImage} style={{width: "100%", height: "100%"}}/>
          </Card>
        : null}
      <Box style={{
        display: "flex", flexDirection: "column", flexGrow: 1, whiteSpace: "nowrap", overflow: "hidden",
      }}>
        <Typography style={{
          fontWeight: "bold",
          textAlign: "start",
          fontSize: "120%",
          textOverflow: "ellipsis"
        }}>{props.title}</Typography>
        <Typography style={{textAlign: "start"}}>{props.text}</Typography>
      </Box>
    </Box>
    {props.accessory}
  </Button>;
}

export function StyledEmpty(props: { emptyConfig: EmptyConfig, style?: any }) {
  let emptyConfig = props.emptyConfig;
  if (!emptyConfig) {
    return null;
  }
  let IconType = emptyConfig.iconType;
  let style = props.style || {};
  return <Box style={{
    display: "flex",
    flexDirection: "column",
    color: mediumGray,
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    ...style,
  }}>
    <Box style={{
      display: "flex",
      flexDirection: "column",
      maxWidth: 360,
      alignItems: "center",
    }}>
      <IconType style={{fontSize: 72}}/>
      <Typography variant="h5" style={{marginTop: PD_SM}}>{emptyConfig.title}</Typography>
      {Boolean(emptyConfig.text) ?
        <Typography style={{marginTop: PD_SM, textAlign: "center"}}>{emptyConfig.text}</Typography>
        : null}
      <Box style={{display: "flex", alignItems: "center", gap: PD_SM}}>
        {Boolean(emptyConfig.action) ? <Button variant="contained" style={{marginTop: PD_MD}}
                                               onClick={(event) => emptyConfig.action.onClick(event)}>
            {emptyConfig.action.text}
          </Button>
          : null}
        {Boolean(emptyConfig.altAction) ? <Button
            variant="outlined" style={{marginTop: PD_MD}}
            onClick={(event) => emptyConfig.altAction.onClick(event)}>{emptyConfig.altAction.text}</Button>
          : null}
      </Box>
    </Box>
  </Box>;
}

export function StyledStream(props: { item: Item, onClick?: () => void }) {
  const item = props.item;
  return <Button
    style={{
      flexShrink: 0,
      paddingLeft: PD_XSM,
      paddingRight: PD_XSM,
    }}
    color="secondary"
    onClick={props.onClick}>
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: 320,
        gap: PD_MD,
      }}> <Card style={{width: "100%", aspectRatio: 1}}>
      <img style={{width: "100%", height: "100%", objectFit: "cover"}} src={item.img}/>
    </Card>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          height: "6em",
        }}
      >
        {item.title ? <Typography style={{fontWeight: "bold", lineHeight: 1.25}}>{item.title}</Typography> : null}
        {item.text ? <Typography style={{lineHeight: 1.25,}}>{item.text}</Typography> : null}
      </Box>
    </Box>
  </Button>;
}
