import React, {ReactElement} from "react";
import {Box, IconButton, Typography} from "@mui/material";
import {PD_LG, PD_MD, SZ_HEADER} from "./dimens";
import {BaseFragment, BaseFragmentState} from "./BaseFragment";
import {colorPrimary, white} from "./colors";
import {ArrowBackIosRounded} from "@mui/icons-material";
import App from "./App";

export type BaseFullscreenDialogFragmentProps = {
  title?: string,
}

export type BaseFullscreenDialogFragmentState = BaseFragmentState & {}

export abstract class BaseFullscreenDialogFragment<P extends BaseFullscreenDialogFragmentProps = BaseFullscreenDialogFragmentProps, S extends BaseFullscreenDialogFragmentState = BaseFullscreenDialogFragmentState> extends BaseFragment<P, S> {

  protected renderContainerContent(): React.ReactElement | null {
    return <Box
      style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", gap: PD_MD, background: white}}>
      <Box style={{
        display: "flex",
        flexShrink: 0,
        height: SZ_HEADER,
        alignItems: "center",
        position: "relative",
        marginLeft: PD_LG,
        marginRight: PD_LG,
        marginBottom: -48,
        gap: PD_MD,
      }}>
        {this.props.title ? <Typography variant="h4" style={{
          width: "100%",
          position: "absolute",
          textAlign: "center",
          paddingBottom: PD_MD,
        }}>{this.props.title}</Typography>
          : null}
        <IconButton
          style={{
            background: colorPrimary,
            color: white
          }}
          onClick={() => {
            App.CONTEXT.hideDialog();
          }}>
          <ArrowBackIosRounded style={{
            margin: 2,
            width: 40,
            height: 40,
          }}/>
        </IconButton>
      </Box>
      <Box
        style={{
          display: "flex",
          flexGrow: 1,
          height: "100%",
          flexDirection: "column",
          gap: PD_MD,
          paddingLeft: PD_MD,
          paddingRight: PD_MD,
          position: "relative",
          overflowY: "scroll",
        }}>
        {this.renderFullscreenDialogContent()}
      </Box>
    </Box>;
  }

  protected renderFullscreenDialogContent(): ReactElement | null {
    return null;
  }
}
