import {Box, Typography} from "@mui/material";
import {BORDER_RADIUS, DW_MD, PD_MD} from "./dimens";
import React from "react";
import {BASE_STATE, BaseFragment, BaseFragmentState} from "./BaseFragment";
import App from "./App";
import {Auth} from "@firebase/auth";
import {colorYellow} from "./colors";
import {LocalActivityOutlined} from "@mui/icons-material";
import {StyledListItem} from "./Components";

export type PlayPassFragmentProps = {
  auth: Auth,
}

type PlayPassFragmentState = BaseFragmentState & {
}

export class PlayPassFragment extends BaseFragment<PlayPassFragmentProps, PlayPassFragmentState> {
  constructor(props: PlayPassFragmentProps, context: any) {
    super(props, context);
    this.state = {
      ...BASE_STATE,
    }
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <Box style={{display: "flex", flexDirection: "column", maxWidth: DW_MD, gap: PD_MD, padding: PD_MD}}>
      <Typography variant="h4">Get Play Pass</Typography>
      <Box style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 192,
        aspectRatio: 1,
        alignSelf: "center",
        background: colorYellow,
        borderRadius: BORDER_RADIUS
      }}>
        <LocalActivityOutlined style={{width: "80%", height: "80%"}}/>
      </Box>
      <Typography style={{textAlign: "center"}}>Get access to the entire music library with hundreds of songs. Upload
        your own music with up to 500 GB of storage.</Typography>
      <Box style={{display: "flex", flexDirection: "column", width: "100%"}}>
        <StyledListItem
          title="Monthly"
          text="Cancel anytime."
          variant="text"
          accessory={<Typography variant="h6">$4.99</Typography>}
          onClick={() => {
            App.CONTEXT.hideDialog();
          }
          }/>
        <StyledListItem
          title="1 year"
          text="Billed once per year."
          variant="text"
          accessory={<Typography variant="h6">$49.99</Typography>}
          onClick={() => {
            App.CONTEXT.hideDialog();
          }
          }/>
        <StyledListItem
          title="Lifetime"
          text="Pay once, use forever."
          variant="text"
          accessory={<Typography variant="h6">$99.99</Typography>}
          onClick={() => {
            App.CONTEXT.hideDialog();
          }
          }/>
      </Box>
    </Box>;
  }
}
