import React, {ReactElement} from "react";
import {Auth, User} from "@firebase/auth";
import {Box, Button, Fab} from "@mui/material";
import {DW_XL, PD_MD, PD_SM} from "./dimens";
import {StyledListItem} from "./Components";
import {deleteObject, getStorage, ref as storageRef} from "@firebase/storage";
import {md5} from "./md5";
import {CloseOutlined, DeleteOutlined, UploadFileOutlined} from "@mui/icons-material";
import {Action, EmptyConfig, Library, Playlist, Queue, Song} from "./types";
import {BASE_STATE, BaseFragment, BaseFragmentState} from "./BaseFragment";
import {getDatabase, push, ref as dbRef, remove, set} from "@firebase/database";
import {UploadProgressFragment} from "./UploadProgressFragment";
import App from "./App";

export enum Mode {
  DEFAULT = "default",
  PLAYLIST = "playlist",
  ADD_TO_PLAYLIST = "add_to_playlist",
}

export type SongsFragmentProps = {
  auth: Auth,
  mode: Mode,
  playlist?: Playlist,
}

type SongsFragmentState = BaseFragmentState & {}

export class SongsFragment extends BaseFragment<SongsFragmentProps, SongsFragmentState> {

  private readonly library = Library.getInstance();
  private readonly queue = Queue.getInstance();

  constructor(props: SongsFragmentProps, context: any) {
    super(props, context);
    this.state = {
      ...BASE_STATE,
    };
  }

  protected fetchOnMount(): Promise<void> {
    switch (this.props.mode) {
      default:
      case Mode.DEFAULT:
        return this.library.loadSongs(this.props.auth.currentUser as User);
      case Mode.PLAYLIST:
        return this.library.loadPlaylistSongs(this.props.auth.currentUser as User, this.props.playlist.id);
    }
    return super.fetchOnMount();
  }

  protected getEmptyConfig(): EmptyConfig {
    return {
      iconType: UploadFileOutlined,
      title: "No songs found",
      text: "Use the upload button to add songs to your library.",
    };
  }

  protected renderContainerContent(): ReactElement | null {
    let songs;
    switch (this.props.mode) {
      default:
      case Mode.DEFAULT:
        songs = this.library.getSongs();
        break;
      case Mode.PLAYLIST:
        songs = this.library.getSongs(this.props.playlist?.id);
        break;
    }
    return <Box style={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      paddingLeft: PD_MD,
      paddingRight: PD_MD,
    }}>
      <Box style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: DW_XL,
        margin: "auto",
        gap: PD_SM,
      }}>
        {songs.length > 0 ? songs.map(song => <StyledListItem
            title={song.title}
            text={song.text}
            object={song}
            accessory={this.renderAccessory(song, this.props.mode)}
            onClick={object => {
              if (this.props.mode === Mode.DEFAULT) {
                this.queue.addSong(object);
              }
            }}/>)
          : this.renderEmpty()}
      </Box>
      {this.props.mode === Mode.DEFAULT ? <Fab
          variant="extended"
          color="secondary"
          style={{position: "absolute", right: 24, bottom: 24, padding: 24}}
          onClick={() => {
            const input = document.createElement('input');
            input.type = 'file';
            input.multiple = true;
            input.accept = "audio/*";
            input.onchange = e => {
              const file = input.files?.[0];
              if (file) {
                App.CONTEXT.showDialog(null, () => <UploadProgressFragment
                  path={"library/songs/" + this.props.auth.currentUser?.uid + "/" + md5("" + Date.now(), file?.name)}
                  file={file}
                  onDone={() => {
                    App.CONTEXT.hideDialog();
                  }}
                />);
              }
            }
            input.click();
          }}>
          <UploadFileOutlined sx={{mr: 1}}/>
          Upload
        </Fab>
        : null}
    </Box>;
  }

  private renderAccessory(song: Song, mode: Mode): ReactElement {
    switch (mode) {
      default:
      case Mode.DEFAULT:
        return this.renderAccessoryMore([
          new Action("Delete", () => {
            App.CONTEXT.showAlert("Delete Song", "Are you sure want to delete the song? This action cannot be undone.",
              [new Action("Cancel"), new Action("Delete", () => {
                const storage = getStorage();
                const songsRef = storageRef(storage, song.mp3);
                deleteObject(songsRef)
                  .then(() => {
                    this.reload();
                    App.CONTEXT.showToast("Deleted.");
                  })
                  .catch(error => App.CONTEXT.showToast("Oops. Something went wrong: Delete failed."));
              }).makeDestructive()]);
          }, DeleteOutlined),
        ]);
      case Mode.PLAYLIST:
        if (!this.props.playlist || this.props.playlist.id !== song.playlistId) {
          return;
        }
        const db = getDatabase();
        return this.renderAccessoryMore([
          new Action("Remove", () => {
            remove(dbRef(db, "library/playlist-songs/" + this.props.auth.currentUser?.uid + "/" + this.props.playlist.id + "/" + song.playlistSongId))
              .then(() => {
                this.reload();
                App.CONTEXT.showToast("Removed.");
              })
              .catch(error => App.CONTEXT.showToast("Oops. Something went wrong: Remove failed."));
          }, CloseOutlined),
        ]);
        break;
      case Mode.ADD_TO_PLAYLIST:
        return <Button variant="outlined" onClick={() => {
          if (!this.props.playlist) {
            return;
          }
          const db = getDatabase();
          const newPlaylistSongRef = push(dbRef(db, "library/playlist-songs/" + this.props.auth.currentUser?.uid + "/" + this.props.playlist.id));
          set(newPlaylistSongRef, song.mp3).then((value) => console.log("got: " + value)).catch(reason => console.log("Error: " + reason));
        }
        }>Add to playlist</Button>;
    }
  }
}
