import {getStorage, ref as storageRef, uploadBytesResumable, UploadMetadata} from "@firebase/storage";
import {Component} from "react";
import {Box, LinearProgress, Typography} from "@mui/material";
import {DW_SM, PD_MD, PD_SM} from "./dimens";
import App from "./App";

export type UploadProgressFragmentProps = { path: string, file: File, onDone: () => void, }

type UploadProgressFragmentState = {
  progress: number,
}

export class UploadProgressFragment extends Component<UploadProgressFragmentProps, UploadProgressFragmentState> {
  constructor(props: UploadProgressFragmentProps, context: any) {
    super(props, context);
    this.state = {
      progress: 0,
    };
  }

  componentDidMount() {
    const storage = getStorage();
    const uploadRef = storageRef(storage, this.props.path);
    const uploadTask = uploadBytesResumable(uploadRef, this.props.file, {
      customMetadata: {
        "name": this.props.file.name,
      },
    } as UploadMetadata);

    uploadTask.on("state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({progress: progress});
      },
      (error) => {
        App.CONTEXT.showToast("Oops. Something went wrong: Upload failed.");
      },
      () => {
        App.CONTEXT.showToast("Uploaded.");
        this.props.onDone();
      }
    );
  }

  render() {
    return <Box style={{display: "flex", flexDirection: "column", width: DW_SM, gap: PD_SM, padding: PD_MD}}>
      <Typography variant="h5">
        Please wait...
      </Typography>
      <LinearProgress
        variant="determinate"
        value={this.state.progress}
      />
      <Typography>
        Uploading: {"" + Math.floor(this.state.progress)}
      </Typography>
    </Box>;
  }
}
