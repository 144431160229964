import React, {Component, ReactElement} from "react";
import {Box, CircularProgress, IconButton} from "@mui/material";
import {Action, EmptyConfig} from "./types";
import {StyledEmpty} from "./Components";
import {MoreVertRounded} from "@mui/icons-material";
import App from "./App";

export interface BaseFragmentState {
  ready?: boolean,
}

export const BASE_STATE = {};

export abstract class BaseFragment<P, S extends BaseFragmentState = BaseFragmentState> extends Component<P, S> {

  constructor(props: P, context: any) {
    super(props, context);
    this.state = this.onCreateState();
  }

  protected onCreateState(): S {
    return BASE_STATE as S;
  }

  componentDidMount() {
    this.reload();
  }

  protected reload() {
    this.setState({
      ready: false,
    });
    const fetchOnMount = this.fetchOnMount();
    fetchOnMount.then(() => this.setState({
      ready: true,
    }));
  }

  protected async fetchOnMount(): Promise<void> {
    return Promise.resolve();
  }

  render() {
    if (!this.state.ready) {
      return <Box style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <CircularProgress/>
      </Box>;
    }
    return this.renderContainerContent();
  }

  protected renderContainerContent(): ReactElement | null {
    return null;
  }

  protected getEmptyConfig(): EmptyConfig {
    return null;
  }

  protected renderEmpty(): ReactElement {
    let emptyConfig = this.getEmptyConfig();
    if (!emptyConfig) {
      return null;
    }
    return <StyledEmpty emptyConfig={emptyConfig}/>;
  }

  protected renderAccessoryMore(actions: Action[]): ReactElement {
    return <IconButton
      style={{width: 64, height: 64}}
      onMouseDown={event => event.stopPropagation()}
      onClick={event => {
        event.stopPropagation();
        App.CONTEXT.showActions(event.currentTarget, actions);
      }}>
      <MoreVertRounded/>
    </IconButton>;
  }
}
