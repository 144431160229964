import {BASE_STATE, BaseFragment, BaseFragmentState} from "./BaseFragment";
import React, {ReactElement} from "react";
import {Box, Button, ButtonBase, Card, IconButton, TextField, Typography} from "@mui/material";
import {PD_LG, PD_MD, PD_SM, PD_XSM, SZ_SM} from "./dimens";
import {User, UserCache, UserDisplayName, UserProfilePhoto} from "./types";
import {AccountCircleOutlined, EditOutlined, FaceOutlined, LogoutRounded} from "@mui/icons-material";
import {Auth, signOut} from "@firebase/auth";
import {getDownloadURL, getStorage, ref as storageRef, uploadBytes} from "@firebase/storage";
import {getDatabase, ref as dbRef, set} from "@firebase/database";
import {AVATARS} from "../consts";
import App from "./App";

export type UserFragmentProps = {
  auth: Auth,
  uid: string,
}

type EditDisplayName = {
  firstname?: string,
  lastname?: string,
}

type EditAvatar = {
  avatar?: string,
}

type UserFragmentState = BaseFragmentState & {
  user?: User,
  editDisplayName?: EditDisplayName,
  editAvatar?: EditAvatar,
}

export class UserFragment extends BaseFragment<UserFragmentProps, UserFragmentState> {

  constructor(props: UserFragmentProps, context: any) {
    super(props, context);
    this.state = {
      ...BASE_STATE,
    };
  }

  protected async fetchOnMount(): Promise<void> {
    const user = await UserCache.getInstance().getUser(this.props.uid);
    this.setState({
      user: user,
    });
  }

  componentDidUpdate(prevProps: Readonly<UserFragmentProps>, prevState: Readonly<UserFragmentState>, snapshot?: any) {
    if (this.state.editAvatar?.avatar && (this.state.user?.avatar !== this.state.editAvatar?.avatar)) {
      const user: User = this.state.user;
      const isMe = this.props.auth.currentUser?.uid === this.props.uid;
      if (!isMe) {
        return;
      }
      user.avatar = this.state.editAvatar.avatar;
      user.profilePhotoUrl = "";
      user.profilePhotoFullPath = "";
      this.updateUser(user);
    }
  }

  protected renderContainerContent(): ReactElement | null {
    const user: User = this.state.user;
    const isMe = this.props.auth.currentUser?.uid === this.props.uid;
    const onKeyDown = event => {
      if (event.keyCode === 13) {
        event.preventDefault();
        if (!isMe) {
          return;
        }
        user.firstname = this.state.editDisplayName.firstname;
        user.lastname = this.state.editDisplayName.lastname;
        this.updateUser(user);
      }
    };
    const changeAvatarEnabled = true;
    const setProfilePhotoEnabled = false;
    return <Box>
      <Box style={{display: "flex", flexDirection: "column", gap: PD_MD, padding: PD_LG}}>
        <Box style={{display: "flex", alignItems: "center", gap: PD_MD}}>
          <Card style={{width: 320, aspectRatio: 1}}>
            <img src={UserProfilePhoto(user)}
                 style={{width: "100%", height: "100%", objectFit: "cover"}}/>
          </Card>
          <Box style={{display: "flex", flexDirection: "column", alignItems: "flex-start", gap: PD_SM}}>
            {!this.state.editDisplayName ? <Box style={{display: "flex", alignItems: "flex-start", gap: PD_SM}}>
                <Typography variant="h3">{UserDisplayName(user)}</Typography>
                {isMe ? <IconButton color="primary" onClick={() => this.setState({
                    editDisplayName: {
                      firstname: user.firstname,
                      lastname: user.lastname,
                    }
                  })}>
                    <EditOutlined/>
                  </IconButton>
                  : null}
              </Box>
              : null}
            {this.state.editDisplayName ? <Box style={{display: "flex", gap: PD_SM}}>
                <TextField value={this.state.editDisplayName?.firstname} placeholder="Firstname" onKeyDown={onKeyDown}
                           onChange={event => this.setState({
                             editDisplayName: {
                               firstname: event.target.value,
                               lastname: this.state.editDisplayName.lastname
                             }
                           })}/>
                <TextField value={this.state.editDisplayName?.lastname} placeholder="Lastname" onKeyDown={onKeyDown}
                           onChange={event => this.setState({
                             editDisplayName: {
                               firstname: this.state.editDisplayName.firstname,
                               lastname: event.target.value
                             }
                           })}/>
              </Box>
              : null}
            <Typography>{user.email}</Typography>
            {this.props.auth.currentUser?.uid === user.uid ?
              <Box style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                {changeAvatarEnabled ?
                  <Box className="hidescroll" style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 16,
                    height: 96,
                    overflowX: "scroll",
                    width: 320,
                  }}>
                    {this.state.editAvatar ? AVATARS.map(avatar => <ButtonBase onClick={() => {
                        this.setState({
                          editAvatar: {
                            avatar: avatar,
                          }
                        });
                      }
                      }>
                        <Card style={{width: SZ_SM, height: SZ_SM, flexShrink: 0}}>
                          <img src={"/assets/avatars/" + avatar + ".png"} style={{width: "100%", height: "100%"}}/>
                        </Card>
                      </ButtonBase>)
                      : <Button variant="outlined" style={{gap: PD_XSM}}
                                onClick={() => this.setState({
                                  editAvatar: {
                                    avatar: user.avatar,
                                  },
                                })}><FaceOutlined/>Change avatar
                      </Button>}
                  </Box>
                  : null}
                {setProfilePhotoEnabled ? <Box style={{display: "flex", alignItems: "center", gap: 8, height: 96}}>
                    <Button variant="outlined" style={{gap: PD_XSM}}
                            onClick={() => {
                              const storage = getStorage();
                              const input = document.createElement('input');
                              input.type = 'file';
                              input.multiple = true;
                              input.accept = "image/*";
                              input.onchange = e => {
                                const file = input.files?.[0];
                                if (file) {
                                  const profilePhotoRef = storageRef(storage, "users/profile-photos/" + user.uid);
                                  uploadBytes(profilePhotoRef, file)
                                    .then(snapshot => getDownloadURL(snapshot.ref))
                                    .then(url => {
                                      const db = getDatabase();
                                      const userRef = dbRef(db, "users/" + user.uid);
                                      const newUser = {
                                        ...user,
                                        profilePhotoFullPath: profilePhotoRef.fullPath,
                                        uid: user.uid,
                                      } as User;
                                      set(userRef, newUser);
                                      user.profilePhotoUrl = url;
                                      UserCache.getInstance().setUser(user.uid, newUser);
                                      this.reload();
                                      App.CONTEXT.showToast("Uploaded.");
                                    })
                                    .catch(error => App.CONTEXT.showToast("Oops. Something went wrong: Upload failed."));
                                }
                              }
                              input.click();
                            }}><AccountCircleOutlined/>Set profile photo
                    </Button>
                  </Box>
                  : null}
                <Button variant="outlined" color="error" style={{gap: PD_XSM}}
                        onClick={() => {
                          signOut(this.props.auth)
                            .then(() => window.location.href = "/");
                        }}><LogoutRounded/>Log out</Button>
              </Box>
              : null}
          </Box>
        </Box>
      </Box>
    </Box>;
  }

  private updateUser(user: User) {
    const db = getDatabase();
    set(dbRef(db, "users/" + this.props.auth.currentUser?.uid), user)
      .then((value) => {
        App.CONTEXT.showToast("Updated.");
      })
      .catch(error => App.CONTEXT.showToast("Oops. Something went wrong: Update failed."));
    UserCache.getInstance().setUser(user.uid, user);
    this.setState({
      editDisplayName: null,
      editAvatar: null,
    });
  }
}
