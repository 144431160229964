export const FEATURED_DISPLAY_NAMES = {
  "childrens-music": "Children's music",
  "classical": "Classical",
};

export const AVATARS = [
  "ant",
  "bat",
  "beetle",
  "butterfly",
  "camel",
  "cat",
  "chipmunk",
  "deer",
  "dog",
  "dolphin",
  "elephant",
  "fish",
  "giraffe",
  "hedgehog",
  "hippo",
  "kangaroo",
  "monkey",
  "octopus",
  "owl",
  "parrot",
  "peacock",
  "rabbit",
  "rhino",
  "snail",
  "tiger",
  "turtle",
  "whale",
  "zebra",
];

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyD1zruTGsodeufaIwklox1NYTDvFk9v07U",
  authDomain: "fyneplaykids-sonic.firebaseapp.com",
  databaseURL: "https://fyneplaykids-sonic-default-rtdb.firebaseio.com",
  projectId: "fyneplaykids-sonic",
  storageBucket: "fyneplaykids-sonic.appspot.com",
  messagingSenderId: "327912817872",
  appId: "1:327912817872:web:3199929e36c2fd0f750ad5",
  measurementId: "G-2ZCD7E262F"
};
