import {Auth} from "@firebase/auth";
import React from "react";
import {Item, Layout, Queue, Radio, Section, Song, Stream} from "./types";
import {Box, Button, Typography} from "@mui/material";
import {PD_LG, PD_MD, PD_XLG} from "./dimens";
import {BASE_STATE, BaseFragment, BaseFragmentState} from "./BaseFragment";
import {FEATURED_DISPLAY_NAMES} from "../consts";
import {StyledStream} from "./Components";
import App, {DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN} from "./App";
import {SearchFragment} from "./SearchFragment";
import {get, getDatabase, ref as dbRef} from "@firebase/database";

export type RadioFragmentProps = {
  auth: Auth,
}

type RadioFragmentState = BaseFragmentState & {}

export class RadioFragment extends BaseFragment<RadioFragmentProps, RadioFragmentState> {

  private readonly radio = Radio.getInstance();
  private readonly queue = Queue.getInstance();

  private layout: Layout;

  constructor(props: RadioFragmentProps, context: any) {
    super(props, context);
    this.state = {
      ...BASE_STATE,
    };
  }

  protected async fetchOnMount(): Promise<void> {
    await this.radio.loadRadio();
    const radio = this.radio.getRadio();
    const featuredMap = new Map<string, Stream[]>();
    radio.forEach(stream => {
      let streamList = featuredMap.get(stream.featured);
      if (!streamList) {
        streamList = [];
        featuredMap.set(stream.featured, streamList);
      }
      streamList.push(stream);
    });
    const sections: Section[] = [];
    featuredMap.forEach((value: Stream[], key: string) => {
      sections.push({
        title: FEATURED_DISPLAY_NAMES[key] || "Section",
        object: key,
        items: value.map(stream => {
          return {
            object: stream,
            text: stream.title,
            img: stream.coverImage,
          } as Item;
        }),
      });
    });
    this.layout = {
      sections: sections,
    };
    return Promise.resolve();
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <Box style={{
      display: "flex",
      flexDirection: "column",
      paddingLeft: PD_XLG,
      paddingRight: PD_XLG,
      paddingTop: PD_LG,
      paddingBottom: PD_LG,
      // background: "#f0f8ff",
    }}>
      {this.layout?.sections.map(section => <Box style={{display: "flex", flexDirection: "column", gap: PD_MD}}>
        <Box style={{display: "flex", flexDirection: "row", width: "100%", alignItems: "center"}}>
          <Typography variant="h5">{section.title}</Typography>
          <div style={{flexGrow: 1}}/>
          <Button variant="text"
                  onClick={() => {
                    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN}, () =>
                      <SearchFragment
                        title="Search" featured={section.object as string} auth={this.props.auth}/>);
                  }}>
            <Typography style={{fontSize: "125%", fontWeight: "bold"}}>SEE ALL</Typography>
          </Button>
        </Box>
        <Box className="hidescroll" style={{
          display: "flex",
          gap: PD_MD,
          marginLeft: -72,
          marginRight: -72,
          paddingLeft: 48,
          paddingRight: 48,
          flexDirection: "row",
          overflowX: "scroll",
        }}>
          {section.items.slice(0, 12).map(item => <StyledStream item={item} onClick={() => {
            const stream = item.object as Stream;
            this.playStream(stream);
          }}/>)}
        </Box>
      </Box>)}
    </Box>;
  }

  private async playStream(stream: Stream) {
    const songs: Song[] = [];
    const db = getDatabase();
    for (const songId of stream.songIds) {
      const songRef = dbRef(db, "songs/" + songId);
      const result = await get(songRef);
      if (result.exists()) {
        const song = result.val() as Song;
        songs.push(song);
      }
    }
    this.queue.setSongs(songs);
  }
}
