import {Auth} from "@firebase/auth";
import {Box, Button, Typography} from "@mui/material";
import {PD_MD, PD_XSM} from "./dimens";
import React, {Component} from "react";
import {Mode, SongsFragment} from "./SongsFragment";
import {PlaylistsFragment} from "./PlaylistsFragment";
import {MusicVideoOutlined, QueueMusicOutlined} from "@mui/icons-material";

export type LibraryFragmentProps = {
  auth: Auth,
}

type LibraryFragmentState = {
  tab: Tab,
}

export enum Tab {
  SONGS = "songs",
  PLAYLISTS = "playlists",
}

export class LibraryFragment extends Component<LibraryFragmentProps, LibraryFragmentState> {

  constructor(props: LibraryFragmentProps, context: any) {
    super(props, context);
    this.state = {
      tab: Tab.SONGS,
    };
  }

  render(): JSX.Element {
    let rendered;
    switch (this.state.tab) {
      case Tab.SONGS:
        rendered = <SongsFragment auth={this.props.auth} mode={Mode.DEFAULT}/>;
        break;
      case Tab.PLAYLISTS:
        rendered = <PlaylistsFragment auth={this.props.auth}/>;
        break;
    }
    return <>
      <Box style={{display: "flex", gap: PD_MD, margin: "auto"}}>
        <Button
          color="secondary"
          variant={this.state.tab === Tab.SONGS ? "contained" : "outlined"}
          onClick={() => this.setState({tab: Tab.SONGS})}>
          <Box style={{display: "flex", gap: PD_XSM, alignItems: "center"}}>
            <MusicVideoOutlined/>
            <Typography variant="h6">Songs</Typography>
          </Box>
        </Button>
        <Button
          color="secondary"
          variant={this.state.tab === Tab.PLAYLISTS ? "contained" : "outlined"}
          onClick={() => this.setState({tab: Tab.PLAYLISTS})}>
          <Box style={{display: "flex", gap: PD_XSM, alignItems: "center"}}>
            <QueueMusicOutlined/>
            <Typography variant="h6">Playlists</Typography>
          </Box>
        </Button>
      </Box>
      {rendered}
    </>;
  }
}
