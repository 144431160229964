import {BASE_STATE, BaseFragment, BaseFragmentState} from "./BaseFragment";
import React, {ReactElement} from "react";
import {Box, Button, Card, Typography} from "@mui/material";
import {PD_LG, PD_MD, PD_SM, PD_XSM, SZ_HEADER} from "./dimens";
import {Playlist, User, UserDisplayName} from "./types";
import {DeleteOutlined, MoreVertRounded, ShuffleOutlined} from "@mui/icons-material";
import {Mode, SongsFragment} from "./SongsFragment";
import {Auth} from "@firebase/auth";
import defaultImage from "../res/images/default_music.png";

export type PlaylistFragmentProps = {
  auth: Auth,
  playlist: Playlist,
  createdBy: User,
}

type PlaylistFragmentState = BaseFragmentState & {}

export class PlaylistFragment extends BaseFragment<PlaylistFragmentProps, PlaylistFragmentState> {

  constructor(props: PlaylistFragmentProps, context: any) {
    super(props, context);
    this.state = {
      ...BASE_STATE,
    };
  }

  protected renderContainerContent(): ReactElement | null {
    return <Box style={{width: "100%"}}>
      <Box style={{display: "flex", flexDirection: "column", gap: PD_MD, padding: PD_LG}}>
        <Box style={{display: "flex", alignItems: "center", gap: PD_MD}}>
          <Card style={{width: 320, aspectRatio: 1}}>
            <img src={defaultImage} style={{width: "100%", height: "100%"}}/>
          </Card>
          <Box style={{display: "flex", flexDirection: "column", alignItems: "flex-start", gap: PD_SM}}>
            <Typography variant="h3">{this.props.playlist.title}</Typography>
            <Typography>{"Playlist " + UserDisplayName(this.props.createdBy)}</Typography>
            <Box style={{display: "flex", alignItems: "flex-start", gap: PD_SM}}>
              <Button variant="contained" style={{gap: PD_XSM}}><ShuffleOutlined/>Shuffle</Button>
              <Button variant="outlined" color="error" style={{gap: PD_XSM}}><DeleteOutlined/>Delete</Button>
              <Button variant="outlined" style={{gap: PD_XSM}}><MoreVertRounded/>More options</Button>
            </Box>
          </Box>
        </Box>
        <SongsFragment auth={this.props.auth} mode={Mode.PLAYLIST} playlist={this.props.playlist}/>
        <Box style={{display: "flex", height: SZ_HEADER, alignItems: "center", marginBottom: -24}}>
          <Typography variant="h5">Suggestions</Typography>
        </Box>
        <SongsFragment auth={this.props.auth} mode={Mode.ADD_TO_PLAYLIST} playlist={this.props.playlist}/>
      </Box>
    </Box>;
  }
}
