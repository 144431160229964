import {createTheme, ThemeOptions} from "@mui/material";
import {BORDER_RADIUS, CARD_SHADOW, PD_SM, PD_XSM} from "./dimens";

export const colorDefault = '#ff3300';
export const colorPrimary = '#8d309b';
export const colorPrimaryDark = '#00aeef';
export const colorAccent = '#fed01b';
export const colorHighlight = '#FFFCE9';
export const colorHighlightAlt = '#BAE1ff';
export const colorLink = '#34B7F1';
export const inactiveAddButtonColor = '#B3B3B3';
export const colorWhiteSmoke = '#efefef'
export const colorRed = '#ed1c24';
export const colorLightGreen = '#91c653';
export const colorDarkGreen = '#009588';
export const colorGreen = '#52b257';
export const colorBlue = '#13aaef';
export const colorDarkBlue = '#003366';
export const colorYellow = '#fed01b';
export const colorOrange = '#ffa115';
export const colorPurple = '#c682d2';
export const colorDarkPurple = '#8d309b';
export const colorPink = '#f06292';
export const colorDarkPink = '#8f1537';
export const colorBrown = '#ae8e6c';
export const colorDarkBrown = '#5d3a3a';
export const colorGray = '#ccc';
export const dividerDark = '#2fff';
export const white = '#fff';
export const black = '#000';
export const gray = '#eee';
export const lightGray = '#f7f7f7';
export const mediumGray = '#999';
export const nightGray = '#222';
export const darkGray = '#000';
export const translucentCardLight = '#CFFF';
export const translucentCardDark = '#C000';
export const translucentNavigationLight = '#CFFF';
export const translucentWhite = 'rgba(255, 255, 255, 0.8)';
export const translucentGray = '#33333366';
export const semiTransparentBlack = 'rgba(0, 0, 0, 0.25)';
export const translucentBlack = 'rgba(0, 0, 0, 0.5)';
export const semiOpaqueBlack = 'rgba(0, 0, 0, 0.75)';
export const almostBlack = 'rgba(0, 0, 0, 0.9)';
export const translucentNavigation = '#6000';

export const colorPastelRed = '#ffb3ba';
export const colorPastelOrange = '#ffdfba';
export const colorPastelYellow = '#ffffba';
export const colorPastelGreen = '#baffc9';
export const colorPastelBlue = '#bae1ff';

export const colorPhotos = colorLightGreen;

export const brightPaletteColors = [
  colorRed,
  colorLightGreen,
  colorDarkGreen,
  colorGreen,
  colorBlue,
  colorYellow,
  colorOrange,
  colorPurple,
  colorPink,
  colorBrown,
];

export const darkPaletteColors = [
  colorDarkBlue,
  colorDarkPurple,
  colorDarkPink,
  colorDarkBrown,
];

export const paletteColors = [
  ...brightPaletteColors,
  ...darkPaletteColors,
];

export class PaletteGradient {

  constructor(readonly name: string, readonly representation: string) {
  }
}

function pg(name: string, ...colors: string[]) {
  return new PaletteGradient(name, "@gradient/" + colors.join(","));
}

export const PaletteGradients = [
  pg("Rave", colorDarkPurple, colorDarkPink),
  pg("Favorite coffee shop", colorBrown, colorDarkBrown),
  pg("Pouring rain", colorBlue, colorLightGreen),
  pg("Beachside camp", colorYellow, colorPink, colorGreen, colorBlue),
  pg("Train journey", colorLightGreen, colorDarkBrown),
  pg("Walk in the park", colorGreen, colorDarkGreen),
  pg("Rainforest expedition", colorDarkGreen, black),
  pg("Misty drive", colorBlue, colorYellow),
  pg("Beautiful sunset", colorRed, colorOrange),
  pg("Calm twilight", colorDarkBlue, colorBlue),
];

export const DEFAULT_THEME_OPTIONS = {
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation: {
          boxShadow: CARD_SHADOW,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          paddingTop: PD_XSM,
          paddingBottom: PD_XSM,
          paddingLeft: PD_SM,
          paddingRight: PD_SM,
          textTransform: "none",
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          width: 96,
          fontWeight: "bold",
          borderRadius: BORDER_RADIUS,
        },
      }
    }
  },
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  typography: {
    h1: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h2: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h3: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h4: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h5: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h6: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    fontFamily: 'THICCCBOI, sans-serif',
    fontSize: 15,
  },
  palette: {
    primary: {
      main: colorDefault,
      contrastText: "#fff",
    },
    secondary: {
      main: "#000",
      contrastText: "#fff",
    },
  }
} as ThemeOptions;

export const DEFAULT_THEME = createTheme(DEFAULT_THEME_OPTIONS);
