import {Box, ButtonBase, Card, Fab, IconButton, SvgIcon, Tab, Tabs, Typography} from "@mui/material";
import {Auth} from "@firebase/auth";
import {PD_LG, PD_MD, PD_SM, SZ_HEADER, SZ_SM} from "./dimens";
import React from "react";
import {User, UserCache, UserProfilePhoto} from "./types";
import {LocalActivityOutlined, RadioOutlined, SearchOutlined, SubscriptionsOutlined} from "@mui/icons-material";
import {gray, nightGray} from "./colors";
import {PlaceholderFragment} from "./PlaceholderFragment";
import App, {
  DIALOG_FLAG_DISABLE_BACKDROP_CLICK,
  DIALOG_FLAG_SHOW_CLOSE,
  DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN
} from "./App";
import {AboutFragment} from "./AboutFragment";
import {LibraryFragment} from "./LibraryFragment";
import {SearchFragment} from "./SearchFragment";
import {UserFragment} from "./UserFragment";
import {PlayerFragment} from "./PlayerFragment";
import {PlayPassFragment} from "./PlayPassFragment";
import {RadioFragment} from "./RadioFragment";

export type MainProps = {
  auth: Auth,
}

export enum TabType {
  LIBRARY = "library",
  RADIO = "radio",
}

type TabInfo = {
  type: TabType,
  text?: string,
  iconType?: typeof SvgIcon,
}

const TABS: TabInfo[] = [
  // {
  //   type: TabType.LIBRARY,
  //   text: "Library",
  //   iconType: SubscriptionsOutlined,
  // },
  {
    type: TabType.RADIO,
    text: "Radio",
    iconType: RadioOutlined,
  },
]

type MainState = {
  tabType: TabType,
  user?: User,
}

export class Main extends React.Component<MainProps, MainState> {

  constructor(props: MainProps, context: any) {
    super(props, context);
    this.state = {
      tabType: TABS[0].type,
    };
  }

  componentDidMount() {
    this.fetchUser();
  }

  private async fetchUser() {
    const user = await UserCache.getInstance().getUser(this.props.auth.currentUser.uid);
    this.setState({
      user: user,
    });
  }

  render() {
    let tabRendered = null;
    switch (this.state.tabType) {
      case TabType.LIBRARY:
        tabRendered = <LibraryFragment auth={this.props.auth}/>;
        break;
      case TabType.RADIO:
        tabRendered = <RadioFragment auth={this.props.auth}/>;
        break;
    }
    return <Box style={{
      display: "flex",
      height: "100%",
    }}>
      <Box style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}>
        <Box style={{
          display: "flex",
          flexShrink: 0,
          height: SZ_HEADER,
          alignItems: "center",
          position: "relative",
          marginLeft: PD_LG,
          marginRight: PD_LG,
          gap: PD_MD,
        }}>
          {TABS.length == 1
            ? <Typography variant="h3" style={{width: "100%", position: "absolute", textAlign: "center"}}>{TABS[0].text}</Typography>
            : <Tabs
              TabIndicatorProps={{
                style: {
                  height: 0,
                  maxWidth: 64,
                  marginLeft: 16,
                  borderRadius: 2,
                }
              }}
              value={this.state.tabType}
              style={{width: "100%", position: "absolute"}} centered
              onChange={(event, value) => this.setState({tabType: value})}>
              {TABS.map(tab => {
                const IconType = tab.iconType;
                return <Tab icon={<IconType style={{width: 32, height: 32}}/>} label={tab.text} value={tab.type} sx={{
                  "&.Mui-selected": {
                    backgroundColor: gray,
                  }
                }}/>
              })}
            </Tabs>
          }
          <ButtonBase
            onClick={() => App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK}, () =>
              <AboutFragment auth={this.props.auth}/>)}
          ><img src="/sonic_icon.png" style={{height: 64}}/></ButtonBase>
          <span style={{flexGrow: 1}}/>
          <Box style={{display: "flex", alignItems: "center", gap: PD_SM}}>
            <Fab
              variant="extended"
              color="primary"
              onClick={() => App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK}, () =>
                <PlayPassFragment auth={this.props.auth}/>)}>
              <LocalActivityOutlined sx={{mr: 1}}/>
              Get Play Pass
            </Fab>
            <IconButton
              style={{
                background: gray,
                color: nightGray,
                width: SZ_SM,
                height: SZ_SM,
              }}
              onClick={() => {
                App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN}, () =>
                  <SearchFragment
                    title="Search" auth={this.props.auth}/>);
              }}>
              <SearchOutlined style={{
                width: "100%",
                height: "100%",
              }}/>
            </IconButton>
            <Card style={{width: SZ_SM, height: SZ_SM, flexShrink: 0}}>
              <ButtonBase
                onClick={() => App.CONTEXT.showDialog(
                  null,
                  () => <UserFragment auth={this.props.auth}
                                      uid={this.state.user.uid}/>)}
              >
                <img src={UserProfilePhoto(this.state.user)} style={{width: "100%", height: "100%"}}/>
              </ButtonBase>
            </Card>
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            gap: PD_MD,
            position: "relative",
          }}>
          {tabRendered}
        </Box>
      </Box>
      <PlayerFragment auth={this.props.auth}/>
    </Box>;
  }
}

