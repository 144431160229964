import React, {ReactElement} from "react";
import {Auth, User as FirebaseUser} from "@firebase/auth";
import {Box, Button, Fab, TextField, Typography} from "@mui/material";
import {DW_SM, PD_MD, PD_SM} from "./dimens";
import {DeleteOutlined, EditOutlined, PlaylistAddOutlined} from "@mui/icons-material";
import {Action, EmptyConfig, Library, Playlist, Queue, User, UserCache, UserDisplayName} from "./types";
import {BASE_STATE, BaseFragment, BaseFragmentState} from "./BaseFragment";
import {getDatabase, push, ref as dbRef, set} from "@firebase/database";
import {StyledListItem} from "./Components";
import {PlaylistFragment} from "./PlaylistFragment";
import {deleteObject, getStorage, ref as storageRef} from "@firebase/storage";
import App from "./App";

export type PlaylistsFragmentProps = {
  auth: Auth,
}

type CreatePlaylist = {
  title: string,
  description: string,
}

type PlaylistItem = {
  playlist: Playlist,
  createdBy: User,
}

type PlaylistsFragmentState = BaseFragmentState & {
  items?: PlaylistItem[],
}

export class PlaylistsFragment extends BaseFragment<PlaylistsFragmentProps, PlaylistsFragmentState> {

  private readonly library = Library.getInstance();
  private readonly queue = Queue.getInstance();

  constructor(props: PlaylistsFragmentProps, context: any) {
    super(props, context);
    this.state = {
      ...BASE_STATE,
    };
  }

  protected async fetchOnMount(): Promise<void> {
    await this.library.loadPlaylists(this.props.auth.currentUser as FirebaseUser);
    this.setState({
      items: await Promise.all(this.library.getPlaylists().map(playlist => {
        async function getPlaylistItem(playlist: Playlist) {
          const user = await UserCache.getInstance().getUser(playlist.creator);
          return {
            playlist: playlist,
            createdBy: user,
          } as PlaylistItem;
        }

        return getPlaylistItem(playlist);
      }))
    })
  }

  protected getEmptyConfig(): EmptyConfig {
    return {
      iconType: PlaylistAddOutlined,
      title: "No playlists found",
      text: "Use the create button to add playlists to your library.",
    };
  }

  protected renderContainerContent(): ReactElement | null {
    let items = this.state.items;
    return <Box style={{
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      gap: PD_SM,
      position: "relative",
    }}>
      {items.length > 0 ? items.map(item => <StyledListItem
          title={item.playlist.title}
          text={UserDisplayName(item.createdBy)}
          object={item.playlist}
          accessory={this.renderAccessoryMore([
            new Action("Edit", () => App.CONTEXT.showDialog(null, () => <PlaylistFragment auth={this.props.auth} playlist={item.playlist} createdBy={item.createdBy} />), EditOutlined),
            new Action("Delete", () => {
              App.CONTEXT.showAlert("Delete Playlist", "Are you sure want to delete the playlist? This action cannot be undone.\nThe songs in this playlist will not be deleted.",
                [new Action("Cancel"), new Action("Delete", () => {
                  const storage = getStorage();
                  const songsRef = storageRef(storage, "library/playlists/" + this.props.auth.currentUser?.uid + "/" + item.playlist.id);
                  deleteObject(songsRef)
                    .then(() => {
                      this.reload();
                      App.CONTEXT.showToast("Deleted.");
                    })
                    .catch(error => App.CONTEXT.showToast("Oops. Something went wrong: Delete failed."));
                }).makeDestructive()]);
            }, DeleteOutlined),
          ])}
          onClick={object => {
            // this.queue.addPlaylist(object);
          }}/>)
        : this.renderEmpty()}
      {/*{this.renderEmpty()}*/}
      <Fab variant="extended"
           color="secondary"
           style={{position: "absolute", right: 24, bottom: 24, padding: 24}}
           onClick={() => {
             this.createPlaylist = {
               title: "",
               description: "",
             };
             App.CONTEXT.showDialog(null, props => this.renderCreatePlaylistDialog());
           }}>
        <PlaylistAddOutlined sx={{mr: 1}}/>
        Create
      </Fab>
    </Box>;
  }

  private createPlaylist: CreatePlaylist | null;

  private renderCreatePlaylistDialog() {
    return <Box style={{display: "flex", flexDirection: "column", padding: PD_MD, gap: PD_MD, minWidth: DW_SM}}>
      <Typography variant="h6">Create playlist</Typography>
      <TextField placeholder="Title" onChange={event => this.createPlaylist.title = event.target.value}/>
      <TextField placeholder="Description" onChange={event => this.createPlaylist.description = event.target.value}/>
      <Button variant="contained" onClick={() => {
        App.CONTEXT.hideDialog();
        const db = getDatabase();
        const newPlaylistRef = push(dbRef(db, "library/playlists/" + this.props.auth.currentUser?.uid));
        set(newPlaylistRef, {
          title: this.createPlaylist.title,
          description: this.createPlaylist.description,
          created: Date.now(),
          creator: this.props.auth.currentUser.uid,
        } as Playlist).then((value) => console.log("got: " + value)).catch(reason => console.log("Error: " + reason));
      }}>Create</Button>
    </Box>;
  }
}
