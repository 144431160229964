import {Box, Card, IconButton, Typography} from "@mui/material";
import {Auth} from "@firebase/auth";
import {PD_MD, PD_SM} from "./dimens";
import defaultImage from "../res/images/default_music.png";
import {
  PauseRounded,
  PlayArrowRounded,
  RepeatOneOnRounded,
  RepeatOnRounded,
  RepeatRounded,
  ShuffleOutlined,
  SkipNextRounded,
  SkipPreviousRounded
} from "@mui/icons-material";
import {StyledListItem} from "./Components";
import {getDownloadURL, getStorage, ref as storageRef} from "@firebase/storage";
import {Queue, QueueListener, Song} from "./types";
import React, {Component} from "react";

export type PlayerFragmentProps = {
  auth: Auth,
}

type PlayerFragmentState = {
  playing?: boolean,
  shouldPlayIndex: number,
  playIndex: number,
  items?: PlayerItem[],
  repeatIndex: number,
}

type PlayerItem = {
  song: Song,
  index: number,
}

enum Repeat {
  NONE,
  ALL,
  ONE,
}

const REPEAT = [Repeat.NONE, Repeat.ALL, Repeat.ONE];

export class PlayerFragment extends Component<PlayerFragmentProps, PlayerFragmentState> implements QueueListener {

  private readonly queue = Queue.getInstance();
  private audio: HTMLAudioElement;

  constructor(props: PlayerFragmentProps, context: any) {
    super(props, context);
    this.state = {
      shouldPlayIndex: -1,
      playIndex: -1,
      repeatIndex: 0,
    };
    this.audio = document.createElement("audio");
    this.audio.addEventListener("play", () => {
      this.setState({
        playing: true,
      });
    });
    this.audio.addEventListener("pause", () => {
      this.setState({
        playing: false,
      });
    });
    this.audio.addEventListener("ended", () => {
      switch (REPEAT[this.state.repeatIndex]) {
        case Repeat.NONE:
          if (this.state.playIndex === this.state.items.length - 1) {
            return;
          }
          this.skipNext();
          break;
        case Repeat.ALL:
          this.skipNext();
          break;
        case Repeat.ONE:
          this.audio.fastSeek(0);
          break;
      }
    });
  }

  componentDidMount() {
    this.queue.addListener(this);
  }

  onQueueUpdated(queue: Queue, reset?: boolean): void {
    this.setState({
      items: this.queue.getSongs()?.map((song, index) => {
        return {
          song: song,
          index: index,
        };
      }),
      shouldPlayIndex: Boolean(reset) ? 0 : this.state.shouldPlayIndex,
      playIndex: Boolean(reset) ? -1 : this.state.playIndex,
    });
  }

  componentDidUpdate(prevProps: Readonly<PlayerFragmentProps>, prevState: Readonly<PlayerFragmentState>, snapshot?: any) {
    if (this.state.items?.length > 0 && prevState.shouldPlayIndex < 0) {
      this.setState({
        shouldPlayIndex: 0,
      });
    } else if (this.state.shouldPlayIndex !== this.state.playIndex) {
      this.audio.pause();
      const song = this.state.items[this.state.shouldPlayIndex].song;
      const fullPath = song?.mp3;
      if (fullPath) {
        const storage = getStorage();
        getDownloadURL(storageRef(storage, fullPath)).then(url => {
          this.audio.src = url;
          this.audio.play();
        });
      }
      this.setState({
        playIndex: this.state.shouldPlayIndex,
      });
    }
  }

  render(): JSX.Element | null {
    const items = this.state.items;
    const playIndex = this.state.playIndex;
    if (!items?.length || playIndex < 0) {
      return null;
    }
    const playing = items[playIndex];
    const upNext = items.slice(playIndex + 1).concat(...items.slice(0, playIndex));
    let RepeatIconType;
    switch (REPEAT[this.state.repeatIndex]) {
      default:
      case Repeat.NONE:
        RepeatIconType = RepeatRounded;
        break;
      case Repeat.ALL:
        RepeatIconType = RepeatOnRounded;
        break;
      case Repeat.ONE:
        RepeatIconType = RepeatOneOnRounded;
        break;
    }
    return <Box style={{
      width: 448,
      flexShrink: 0,
      background: "#f7f7f7",
      display: "flex",
      flexDirection: "column",
    }}>
      <Box style={{
        display: "flex",
        flexDirection: "column",
      }}>
        <Box style={{display: "flex", padding: PD_MD}}>
          <Typography variant="h5">Now playing</Typography>
        </Box>
        <Box style={{
          display: "flex",
          flexDirection: "column",
          padding: PD_MD,
          gap: PD_MD,
        }}>
          <Box style={{width: 240, margin: "auto"}}>
            <Card style={{width: "100%", aspectRatio: 1}}>
              <img src={playing.song.coverImage || defaultImage} style={{width: "100%", height: "100%", objectFit: "cover"}}/>
            </Card>
          </Box>
          <Box style={{width: "100%", gap: PD_SM, display: "flex", alignItems: "center", justifyContent: "center"}}>
            <IconButton color="primary" onClick={() => this.queue.shuffle()}>
              <ShuffleOutlined style={{width: 32, height: 32}}/>
            </IconButton>
            <IconButton color="primary"
                        onClick={() => this.skipPrevious()}>
              <SkipPreviousRounded style={{width: 48, height: 48}}/>
            </IconButton>
            <IconButton color="primary" onClick={() => this.audio.paused ? this.audio.play() : this.audio.pause()}>
              {this.audio.paused ? <PlayArrowRounded style={{width: 72, height: 72}}/> :
                <PauseRounded style={{width: 72, height: 72}}/>}
            </IconButton>
            <IconButton color="primary"
                        onClick={() => this.skipNext()}>
              <SkipNextRounded style={{width: 48, height: 48}}/>
            </IconButton>
            <IconButton color="primary"
                        onClick={() => this.setState({repeatIndex: (this.state.repeatIndex + 1) % REPEAT.length})}>
              <RepeatIconType style={{width: 32, height: 32}}/>
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: PD_MD,
        paddingRight: PD_MD,
      }}>
        <StyledListItem img={playing.song.coverImage} title={playing.song.title} text={playing.song.description?.[0]}/>
      </Box>
      <Box style={{
        display: "flex",
        flexShrink: 0,
        padding: PD_MD,
        alignItems: "center",
        marginTop: -24,
        marginBottom: -24
      }}>
        <Typography variant="h5">Up next</Typography>
      </Box>
      <Box className="hidescroll" style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: PD_MD,
        paddingRight: PD_MD,
        overflowY: "scroll",
        flexGrow: 1,
      }}>
        {upNext?.map(item => <StyledListItem img={item.song.coverImage} title={item.song.title} text={item.song.description?.[0]}
                                             onClick={() => this.setState({shouldPlayIndex: item.index})}/>)}
      </Box>
    </Box>;
  }

  private skipNext() {
    const items = this.state.items;
    const playIndex = this.state.playIndex;
    this.setState({shouldPlayIndex: (playIndex === items.length - 1) ? 0 : playIndex + 1});
  }

  private skipPrevious() {
    const items = this.state.items;
    const playIndex = this.state.playIndex;
    this.setState({shouldPlayIndex: (playIndex === 0) ? items.length - 1 : playIndex - 1});
  }

  private shuffle() {

  }
}
