import React, {ReactElement} from 'react';
import {
  Backdrop,
  Box,
  Button,
  createTheme,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Popover,
  Snackbar,
  ThemeProvider,
  Typography,
  Zoom
} from "@mui/material";
import '../res/css/App.css';
import {getAuth, User} from '@firebase/auth';
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {Login} from "./Login";
import {Main} from "./Main";
import {DW_SM, PD_MD, PD_SM, PD_XSM, SZ_SM} from "./dimens";
import {black, colorPrimary, DEFAULT_THEME_OPTIONS, lightGray} from "./colors";
import {Close} from "@mui/icons-material";
import {FIREBASE_CONFIG} from "../consts";
import {Action} from "./types";

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);
const analytics = getAnalytics(app);

const theme = createTheme({
  ...DEFAULT_THEME_OPTIONS,
  // shadows: new Array<string>(25).fill("none") as Shadows,
  palette: {
    primary: {
      main: colorPrimary,
      contrastText: "#fff",
    },
    secondary: {
      main: "#000",
      contrastText: "#fff",
    },
  }
});

export type DialogProps = {
  id?: string,
  flags?: number,
  args?: any[],
}

type ShowDialog = {
  flags: number,
  rendered: ReactElement,
  onClose?: () => void,
}

export type PopoverProps = {
  id?: string,
  flags?: number,
  args?: any[],
}

type ShowPopover = {
  anchorEl: HTMLElement,
  rendered: ReactElement,
  onClose?: () => void,
}

type ShowToast = {
  text: string,
  action?: Action,
  onClose?: () => void,
}

export const DIALOG_FLAG_DISABLE_BACKDROP_CLICK = 1 << 0;
export const DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN = 1 << 1;
export const DIALOG_FLAG_SHOW_DIALOG_IMMERSIVE = 1 << 2;
export const DIALOG_FLAG_SHOW_CLOSE = 1 << 3;

type AppProps = {};

type AppState = {
  user?: User | null,
  ready?: boolean,
  hasPhonecall?: boolean,
  phonecallFragmentMinimized?: boolean,
  showToast?: ShowToast | null,
  showDialogs: ShowDialog[],
  showPopover?: ShowPopover | null,
}

function Splash() {
  return <Box style={{
    display: "flex",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center"
  }}>
    <Zoom in timeout={100} unmountOnExit><img src="/sonic_icon.png" style={{height: 128}}/></Zoom>
  </Box>;
}

export interface Context {
  showAlert(title: string, text: string, actions: Action[], onClose?: () => void): void;

  showDialog(props: DialogProps, render: (props: DialogProps) => ReactElement, onClose?: () => void): void;

  hideDialog(): void;

  hideAllDialogs(): void;

  showActions(anchorEl: HTMLElement, actions: Action[], onClose?: () => void): void;

  showPopover(anchorEl: HTMLElement, props: PopoverProps, render: (props: PopoverProps) => ReactElement, onClose?: () => void): void;

  hidePopover();

  showToast(text: string): void,
}

export default class App extends React.Component<AppProps, AppState> implements Context {

  static CONTEXT: Context;

  private readonly auth = getAuth();

  private appInit?: boolean;

  constructor(props: AppProps, context: any) {
    super(props, context);
    this.state = {
      showDialogs: [],
    };
    App.CONTEXT = this;
  }

  componentDidMount() {
    this.auth.onAuthStateChanged((user: User | null) => {
      if (user) {
        if (this.appInit) {
          return;
        }
        this.appInit = true;
        this.onAppInit()
          .then(() => this.setState({
            user: user,
            ready: true,
          }));
      } else {
        this.appInit = false;
        this.setState({
          user: null,
          ready: true,
        });
      }
    });
  }

  private async onAppInit(): Promise<void> {
    return Promise.resolve();
  }

  showAlert(title: string, text: string, actions: Action[], onClose?: () => void): void {
    this.showDialog(null, () => <Box
      style={{width: DW_SM, display: "flex", flexDirection: "column", padding: PD_MD, gap: PD_MD}}>
      <Typography variant="h6">{title}</Typography>
      <Typography>{text}</Typography>
      <Box style={{display: "flex", flexDirection: "row-reverse", gap: PD_SM}}>
        {actions.map(action => <Button
          variant="text"
          color={action.destructive ? "error" : "primary"}
          style={{textTransform: "uppercase"}}
          onClick={(event) => {
            this.hideDialog();
            action.onClick?.(event)
          }}>{action.text}</Button>)}
      </Box>
    </Box>);
  }


  showDialog(props: DialogProps, render: (props: DialogProps) => ReactElement, onClose?: () => void): void {
    if (!onClose) {
      onClose = () => App.CONTEXT.hideDialog();
    }
    let flags = props?.flags || 0;
    this.setState({
      showDialogs: [{
        flags: flags,
        rendered: render(props),
        onClose: onClose,
      } as ShowDialog,
        ...this.state.showDialogs]
    });
  }

  hideDialog(): void {
    this.setState({
      showDialogs: this.state.showDialogs.slice(1),
    });
  }

  hideAllDialogs(): void {
    this.setState({
      showDialogs: [],
    });
  }

  showActions(anchorEl: HTMLElement, actions: Action[], onClose?: () => void): void {
    this.showPopover(
      anchorEl,
      null,
      () => <List>
        {actions.map(action => {
            const IconType = action.iconType;
            return <ListItem>
              <ListItemButton
                onClick={(event) => {
                  App.CONTEXT.hidePopover();
                  action.onClick(event);
                }}>
                {IconType ? <ListItemIcon>
                    <IconType/>
                  </ListItemIcon>
                  : null}
                <Typography style={{marginLeft: -16}}>
                  {action.text}
                </Typography>
              </ListItemButton>
            </ListItem>;
          }
        )}
      </List>,
      onClose);
  }

  showPopover(anchorEl: HTMLElement, props: PopoverProps, render: (props: PopoverProps) => ReactElement, onClose?: () => void): void {
    this.setState({
      showPopover: {
        anchorEl: anchorEl,
        rendered: render(props),
        onClose: onClose,
      } as ShowPopover,
    });
  }

  hidePopover() {
    this.setState({
      showPopover: null,
    });
  }

  showToast(text: string, action?: Action, onClose?: () => void): void {
    this.setState({
      showToast: {
        text: text,
        action: action,
        onClose: onClose,
      }
    });
  }

  render() {
    let rendered;
    if (this.state.ready) {
      if (this.auth.currentUser) {
        rendered = <Main auth={this.auth}/>;
      } else {
        rendered = <Login auth={this.auth}/>;
      }
    } else {
      rendered = <Splash/>;
    }
    return <ThemeProvider theme={theme}>
      <Box style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: this.state.showDialogs?.length > 0 || (this.state.hasPhonecall && !this.state.phonecallFragmentMinimized) ? "hidden" : "inherit",
      }}>
        {Boolean(this.state.showToast) ?
          this.renderToast()
          : null}
        {this.state.showDialogs.reverse().map((showDialog, index) => (showDialog.flags & DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN) !== 0 ?
          this.renderFullscreenDialog(showDialog, index)
          : this.renderDialog(showDialog, index))}
        {Boolean(this.state.showPopover) ?
          this.renderPopover()
          : null}
        {rendered}
      </Box>
    </ThemeProvider>;
  }

  private renderToast() {
    let actionRendered = null;
    if (this.state.showToast.action) {
      actionRendered =
        <Button variant="text" onClick={this.state.showToast.action.onClick}>{this.state.showToast.action.text}</Button>
    }
    return <Snackbar
      open
      autoHideDuration={3000}
      onClose={(event) => {
        this.state.showToast.onClose?.();
        this.setState({
          showToast: null,
        });
      }}
      message={this.state.showToast.text}
      action={actionRendered}
    />;
  }

  private renderFullscreenDialog(dialog: ShowDialog, index: number) {
    let immersive = (dialog.flags & DIALOG_FLAG_SHOW_DIALOG_IMMERSIVE) !== 0;
    let showClose = (dialog.flags & DIALOG_FLAG_SHOW_CLOSE) !== 0;
    return <Box style={{position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 1200 + index}}>
      <Backdrop style={{background: immersive ? black : null,}} open>
        <Box width="100%" height="100vh" style={{position: "relative"}}>
          {showClose ? this.renderCloseButton() : null}
          <Box style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: "absolute",
          }}>
            {dialog.rendered}
          </Box>
        </Box>
      </Backdrop>
    </Box>;
  }

  private renderDialog(dialog: ShowDialog, index: number) {
    let showClose = (dialog.flags & DIALOG_FLAG_SHOW_CLOSE) !== 0;
    return <Dialog
      scroll="body"
      maxWidth="xl"
      open
      onClose={(event, reason) => {
        if (reason === "backdropClick" && (dialog.flags & DIALOG_FLAG_DISABLE_BACKDROP_CLICK)) {
          return;
        }
        this.hideDialog();
      }}>
      {showClose ? this.renderCloseButton() : null}
      {dialog.rendered}
    </Dialog>;
  }

  private renderCloseButton(onClose?: () => void) {
    if (!onClose) {
      onClose = () => this.hideDialog();
    }
    return <Box style={{
      display: "flex",
      left: PD_XSM,
      right: PD_XSM,
      top: PD_XSM,
      alignItems: "center",
      justifyContent: "flex-end",
      height: SZ_SM,
      position: "absolute",
      zIndex: 2000,
    }}>
      <IconButton style={{
        width: SZ_SM,
        height: SZ_SM,
        background: lightGray,
      }} onClick={onClose}>
        <Close style={{color: "black"}}/>
      </IconButton>
    </Box>;
  }

  private renderPopover() {
    return <Popover
      anchorEl={this.state.showPopover.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={(event) => {
        this.state.showPopover.onClose?.();
        this.setState({
          showPopover: null,
        });
      }}
      open>
      {this.state.showPopover.rendered}
    </Popover>
  }
}
