import {Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, UserCredential} from "@firebase/auth";
import React, {useState} from "react";
import {Box, Button, Divider, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import {PD_MD} from "./dimens";
import {colorRed} from "./colors";
import {getDatabase, ref as dbRef, set} from "@firebase/database";
import {User, UserCache} from "./types";
import App from "./App";
import {HtmlFragment} from "./HtmlFragment";

enum View {
  LOGIN,
  SIGNUP,
}

function SignupFragment(props: { auth: Auth, setView: (view: View) => void }) {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [agree, setAgree] = useState(false);
  const [error, setError] = useState("");

  function doSignup() {
    if (!validateEmail(email)) {
      setError("Invalid email: " + email + ". Please enter a valid email.");
      return;
    }
    if (password.length < 8) {
      setError("Please enter a password with at least 8 characters.");
      return;
    }
    createUserWithEmailAndPassword(props.auth, email, password)
      .then((value: UserCredential) => {
        const db = getDatabase();
        const user = {
          uid: value.user.uid,
          email: email,
          firstname: firstname,
          lastname: lastname,
        } as User;
        return set(dbRef(db, "users/" + value.user.uid), user).then(() => user);
      })
      .then(user => UserCache.getInstance().setUser(user.uid, user))
      .catch(reason => {
        setError("Error signing up. Please try again later.");
      });
  }

  const onKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      doSignup();
    }
  };

  const signupEnabled = email.length > 0 && firstname.length > 0 && password.length > 0 && agree;
  return <Box style={{display: "flex", flexDirection: "column", gap: PD_MD}}>
    <Typography>Create your own account today. You can then invite family to video chat.</Typography>
    <TextField autoFocus placeholder="Email" type="email" required onKeyDown={onKeyDown}
               onChange={event => setEmail(event.target.value?.trim() || "")}/>
    <Box style={{display: "flex", gap: PD_MD}}>
      <TextField style={{flexGrow: 1}} placeholder="First name" required onKeyDown={onKeyDown}
                 onChange={event => setFirstname(event.target.value?.trim() || "")}/>
      <TextField style={{flexGrow: 1}} placeholder="Last name" onKeyDown={onKeyDown}
                 onChange={event => setLastname(event.target.value?.trim() || "")}/>
    </Box>
    <TextField placeholder="Password" type="password" required onKeyDown={onKeyDown}
               onChange={event => setPassword(event.target.value)}/>
    {error.length > 0 ? <Typography variant="caption" style={{color: colorRed,}}>{error}</Typography> : null}
    <Box style={{display: "flex", gap: 12}}>
      <Checkbox checked={agree} style={{width: 48, height: 48}} onChange={(event, checked) => setAgree(checked)}/>
      <Typography>I have read and agree to the <a href="#"
                                                  onClick={() => App.CONTEXT.showDialog(null, () => <HtmlFragment
                                                    url="/privacy.html"/>)}>Privacy Policy
      </a> and <a href="#" onClick={() => App.CONTEXT.showDialog(null, () => <HtmlFragment url="/terms.html"/>)}>
        Terms & Conditions
      </a>.</Typography>
    </Box>
    <Button variant="contained" disabled={!signupEnabled}
            onClick={() => doSignup()}>Sign Up
    </Button>
    <Divider/>
    <Typography variant="h4">Already have an account?</Typography>
    <Typography>Access it now to continue exploring.</Typography>
    <Button variant="outlined" onClick={() => props.setView(View.LOGIN)}>Log in</Button>
  </Box>;
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function LoginFragment(props: { auth: Auth, setView: (view: View) => void }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  function doLogin() {
    if (!validateEmail(email)) {
      setError("Invalid email: " + email + ". Please enter a valid email.");
      return;
    }
    if (password.length < 8) {
      setError("Please enter a password with at least 8 characters.");
      return;
    }
    signInWithEmailAndPassword(props.auth, email, password)
      .then((value: UserCredential) => {
      }).catch(reason => {
      setError("Error logging in. Please check your email and password, and try again.");
    });
  }

  const onKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      doLogin();
    }
  };

  const loginEnabled = email.length > 0 && password.length > 0;
  return <Box style={{display: "flex", flexDirection: "column", gap: PD_MD}}>
    <Typography>Access your account now to start exploring.</Typography>
    <TextField autoFocus placeholder="Email" type="email" required onKeyDown={onKeyDown}
               onChange={event => setEmail(event.target.value?.trim() || "")}/>
    <TextField placeholder="Password" type="password" required onKeyDown={onKeyDown}
               onChange={event => setPassword(event.target.value?.trim() || "")}/>
    {error.length > 0 ? <Typography variant="caption" style={{color: colorRed,}}>{error}</Typography> : null}
    <Button
      variant="contained"
      disabled={!loginEnabled}
      onClick={() => doLogin()}>Log in</Button>
    <Typography>By using this app, you agree to the <a href="#"
                                                       onClick={() => App.CONTEXT.showDialog(null, () => <HtmlFragment
                                                         url="/privacy.html"/>)}>Privacy
      Policy
    </a> and <a href="#" onClick={() => App.CONTEXT.showDialog(null, () => <HtmlFragment url="/terms.html"/>)}>
      Terms & Conditions
    </a>.</Typography>
    <a href="/forgot"><Typography style={{textAlign: "center"}}>Forgot password?</Typography></a>
    <Divider/>
    <Typography variant="h4">Don’t have an account?</Typography>
    <Typography>Create your own account today. You can then invite family to video chat.</Typography>
    <Button variant="outlined" onClick={() => props.setView(View.SIGNUP)}>Sign Up</Button>
  </Box>;
}

export function Login(props: { auth: Auth }) {
  const [view, setView] = useState(View.LOGIN);

  function getFragment(view: View, setView: (view: View) => void, auth: Auth) {
    switch (view) {
      case View.LOGIN:
        return <LoginFragment auth={auth} setView={setView}/>
      case View.SIGNUP:
        return <SignupFragment auth={auth} setView={setView}/>
    }
  }

  // return <ThemeProvider theme={DEFAULT_THEME}>
  return <Box style={{
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}>
    <Box style={{
      width: "100%",
      padding: 24,
      display: "flex",
      flexDirection: "column",
      maxWidth: 480,
      gap: 48,
      alignItems: "center",
    }}>
      <img src="/sonic_stamp.png" style={{width: 192, marginBottom: -24}}/>
      {getFragment(view, setView, props.auth)}
    </Box>
  </Box>;
  // </ThemeProvider>;
}
