import {FullMetadata, getDownloadURL, getMetadata, getStorage, listAll, ref as storageRef} from "@firebase/storage";
import {SvgIcon} from "@mui/material";
import {get, getDatabase, ref as dbRef} from "@firebase/database";
import {User as FirebaseUser} from "@firebase/auth";
import defaultImage from "../res/images/default_user.png";
import {md5} from "./md5";

export type User = {
  uid: string,
  email?: string,
  firstname?: string,
  lastname?: string,
  avatar?: string,
  profilePhotoFullPath?: string,
  profilePhotoUrl?: string,
}

export function UserDisplayName(user?: User) {
  let displayName = user?.firstname?.length > 0 ? user.firstname : "";
  displayName += user?.lastname?.length > 0 ? ((displayName.length > 0 ? " " : "") + user?.lastname) : "";
  return displayName;
}

export function UserProfilePhoto(user?: User) {
  return (user?.avatar ? "/assets/avatars/" + user.avatar + ".png" : null) || user?.profilePhotoUrl || defaultImage;
}

export function UnknownUser(uid: string): User {
  return {
    uid: uid,
    firstname: "[ Unknown ]",
    lastname: "",
  } as User;
}

export class UserCache {

  private static readonly instance = new UserCache();

  private cache: Map<string, User> = new Map<string, User>();

  static getInstance(): UserCache {
    return this.instance;
  }

  setUser(uid: string, user: User): void {
    this.cache.set(uid, user);
  }

  async getUser(uid: string): Promise<User> {
    let cached = this.cache.get(uid);
    if (cached) {
      return Promise.resolve(cached);
    }
    const db = getDatabase();
    const userRef = dbRef(db, "users/" + uid);
    const result = await get(userRef);
    if (result.exists()) {
      let profilePhotoUrl = null;
      let user = result.val();
      if (user.profilePhotoFullPath?.length > 0) {
        const storage = getStorage();
        profilePhotoUrl = await getDownloadURL(storageRef(storage, "users/profile-photos/" + uid));
      }
      cached = {
        ...user,
        profilePhotoUrl: profilePhotoUrl,
        uid: uid,
      };
      this.cache.set(uid, cached);
      return Promise.resolve(cached);
    }
    return Promise.resolve(UnknownUser(uid));
  }
}

export class ButtonState {

  constructor(readonly disabled?: boolean, readonly selected?: boolean) {
  }

  toggleSelected(): ButtonState {
    return new ButtonState(this.disabled, !this.selected);
  }
}

export type Section = {
  title?: string,
  object?: any,
  items: Item[],
}

export type Item = {
  object?: any,
  title: string,
  text: string,
  rating?: number,
  icon?: string,
  img?: string,
}

export type Layout = {
  sections: Section[],
}

type BaseStream = {
  title: string,
  id: string,
  coverImage?: string,
}

export type Stream = BaseStream & {
  featured?: string,
  songIds: string[],
}

export type Playstream = BaseStream & {
  current: Song,
  next: Song,
}

export type Song = {
  source: string,
  id: string,
  duration?: number,
  title?: string,
  artist?: string,
  album?: string,
  genre?: string[],
  composer?: string[],
  year?: number,
  description?: string[],
  language?: string,
  copyright?: string,
  featured?: string,
  tags?: string[],

  coverImage?: string,
  mp3?: string,

  playlistId?: string, // only set for songs in a playlist
  playlistSongId?: string, // id of song entry in a playlist with 'playlistId'
}

export class Radio {

  private static instance = new Radio();

  static getInstance(): Radio {
    return this.instance;
  }

  private radio: Stream[] = [];

  private readonly radioCache: Map<string, Stream> = new Map<string, Stream>();

  private constructor() {
  }

  async loadRadio(): Promise<void> {
    this.radio = [];
    const db = getDatabase();
    const radioRef = dbRef(db, "radio/streams");
    const result = await get(radioRef);
    const radio: Stream[] = [];
    if (result.exists()) {
      let val = result.val();
      for (const key in val) {
        let value = val[key];
        radio.push({
          ...value,
        } as Stream);

      }
      this.radio = radio;
    }
  }

  getRadio(): Stream[] {
    return this.radio;
  }
}

export type Playlist = {
  id: string,
  title: string,
  description: string,
  created: number,
  creator: string,
  edited?: number,
  editor?: string,
}

export class Library {

  private static instance = new Library();

  static getInstance(): Library {
    return this.instance;
  }

  private songs: Song[] = [];
  private playlists: Playlist[] = [];

  private readonly songsCache: Map<string, Song> = new Map<string, Song>();
  private readonly playlistSongsCache: Map<string, Song[]> = new Map<string, Song[]>();

  private constructor() {
  }

  async loadSongs(user: FirebaseUser): Promise<void> {
    const storage = getStorage();
    const songsRef = storageRef(storage, "library/songs/" + user.uid);
    const result = await listAll(songsRef);
    const metadata = await Promise.all(result.items.map(song => getMetadata(storageRef(storage, song.fullPath))));
    this.songs = metadata.map((m: FullMetadata, index: number) => {
      const fullPath = result.items[index].fullPath;
      const song = this.createSongFromMetadata(user, fullPath, m);
      this.songsCache.set(song.mp3, song);
      return song;
    });
    return Promise.resolve();
  }

  private createSongFromMetadata(user: FirebaseUser, fullPath: string, m: FullMetadata) {
    const title = m.customMetadata?.["name"] as string;
    const song: Song = {
      id: md5(title),
      source: user.uid,
      mp3: fullPath,
      title: title,
    };
    return song;
  }

  async loadPlaylistSongs(user: FirebaseUser, playlistId: string): Promise<void> {
    const storage = getStorage();
    const db = getDatabase();
    const playlistSongsRef = dbRef(db, "library/playlist-songs/" + user?.uid + "/" + playlistId);
    const result = await get(playlistSongsRef);
    const songs: Song[] = [];
    if (result.exists()) {
      let val = result.val();
      for (const key in val) {
        let songFullPath = val[key];
        let song: Song = this.songsCache.get(songFullPath);
        if (!song) {
          const metadata = await getMetadata(storageRef(storage, songFullPath))
          song = this.createSongFromMetadata(user, songFullPath, metadata);
          this.songsCache.set(song.mp3, song);
        } else {
          song = {
            ...song,
            playlistId: playlistId,
            playlistSongId: key,
          };
        }
        songs.push(song);
      }
      this.playlistSongsCache.set(playlistId, songs);
    }
    return Promise.resolve();
  }

  getSongs(playlistId?: string): Song[] {
    if (!playlistId?.length) {
      return this.songs;
    }
    return this.playlistSongsCache.get(playlistId);
  }

  async loadPlaylists(user: FirebaseUser): Promise<void> {
    const db = getDatabase();
    const playlistsRef = dbRef(db, "library/playlists/" + user.uid);
    const result = await get(playlistsRef);
    const playlists: Playlist[] = [];
    if (result.exists()) {
      let val = result.val();
      for (const key in val) {
        let value = val[key];
        playlists.push({
          id: key,
          ...value,
        } as Playlist);

      }
      this.playlists = playlists;
    }
    return Promise.resolve();
  }

  getPlaylists(): Playlist[] {
    return this.playlists;
  }
}

export interface QueueListener {
  onQueueUpdated(queue: Queue, reset?: boolean): void;
}

export class Queue {

  private static sInstance = new Queue();

  static getInstance(): Queue {
    return this.sInstance;
  }

  private songs: Song[] = [];
  private listeners: QueueListener[] = [];

  private constructor() {
  }

  addListener(listener: QueueListener) {
    this.listeners = [...this.listeners, listener];
  }

  private notifyListeners(reset?: boolean) {
    this.listeners.forEach(listener => listener.onQueueUpdated(this, reset));
  }

  shuffle() {
    if (!this.songs?.length) {
      return;
    }
    const newSongs: Song[] = [];
    let array = Array.from(new Array(this.songs.length).keys());
    while (array.length > 0) {
      let index = Math.floor(Math.random() * array.length);
      newSongs.push(this.songs[array[index]]);
      array.splice(index, 1);
    }
    this.songs = newSongs;
    this.notifyListeners(true);
  }

  setSongs(songs: Song[]) {
    this.songs = songs;
    this.notifyListeners(true);
  }

  addSong(song: Song) {
    this.songs = [...this.songs, song];
    this.notifyListeners();
  }

  getSongs(): Song[] {
    return this.songs;
  }
}

export class Action {

  tag?: any;
  destructive?: boolean;

  isSelected?: () => boolean;

  constructor(readonly text: string, readonly onClick?: (event: any, ...args: any[]) => void, readonly iconType?: typeof SvgIcon, readonly iconUrl?: string, readonly disabled?: boolean) {
  }

  makeDestructive(): Action {
    this.destructive = true;
    return this;
  }
}

export type EmptyConfig = {
  iconType: typeof SvgIcon,
  title: string,
  text?: string,
  action?: Action,
  altAction?: Action,
}
